<template>
  <div>
    <div id="pageEditProfile" class="mainContents">
      <UserNavSp :user="user" :page="'profile'" />

      <UserTitle :user="user" />

      <UserNav :user="user" :page="'profile'" />

      <form enctype="multipart/form-data">
        <div class="editProfileArea">
          <h2>プロフィール確認・変更する</h2>
          <div class="inner">
            <div class="group">
              <dl class="name">
                <div>
                  <dt>姓</dt>
                  <dd class="inputWrap" :class="{ error: !validLastname }">
                    <input
                      v-model="user.lastname"
                      type="text"
                      placeholder="山田"
                    >
                  </dd>
                </div>
                <div>
                  <dt>名</dt>
                  <dd class="inputWrap" :class="{ error: !validFirstname }">
                    <input
                      v-model="user.firstname"
                      type="text"
                      placeholder="太郎"
                    >
                  </dd>
                </div>
                <div>
                  <dt>姓（カタカナ）</dt>
                  <dd class="inputWrap" :class="{ error: !validLastnameKana }">
                    <input
                      v-model="user.lastname_kana"
                      type="text"
                      placeholder="ヤマダ"
                    >
                  </dd>
                </div>
                <div>
                  <dt>名（カタカナ）</dt>
                  <dd class="inputWrap" :class="{ error: !validFirstnameKana }">
                    <input
                      v-model="user.firstname_kana"
                      type="text"
                      placeholder="タロウ"
                    >
                  </dd>
                </div>
              </dl>
              <ul class="gender" :class="{ error: !validGender }">
                <li>
                  <label><input v-model="user.gender" type="radio" value="1"><span>男</span></label>
                </li>
                <li>
                  <label><input v-model="user.gender" type="radio" value="2"><span>女</span></label>
                </li>
              </ul>
            </div>
            <div class="group">
              <dl class="birthday">
                <dt>生年月日</dt>
                <dd>
                  <div
                    class="selectWrap year"
                    :class="{ error: !validBirthdayYear }"
                  >
                    <select v-model="user.birthday_year">
                      <option
                        v-for="(year, index) in years"
                        :key="index"
                        :value="year"
                      >
                        {{ year }}
                      </option>
                    </select>
                  </div>
                  <span>年</span>
                </dd>
                <dd>
                  <div
                    class="selectWrap"
                    :class="{ error: !validBirthdayMonth }"
                  >
                    <select v-model="user.birthday_month">
                      <option
                        v-for="(month, index) in months"
                        :key="index"
                        :value="month"
                      >
                        {{ month }}
                      </option>
                    </select>
                  </div>
                  <span>月</span>
                </dd>
                <dd>
                  <div class="selectWrap" :class="{ error: !validBirthdayDay }">
                    <select v-model="user.birthday_day">
                      <option
                        v-for="(day, index) in days"
                        :key="index"
                        :value="day"
                      >
                        {{ day }}
                      </option>
                    </select>
                  </div>
                  <span>日</span>
                </dd>
              </dl>
              <ul class="publish">
                <li>
                  <label><input
                    v-model="user.birthday_display"
                    type="radio"
                    value="true"
                  ><span>公開する</span></label>
                </li>
                <li>
                  <label><input
                    v-model="user.birthday_display"
                    type="radio"
                    value="false"
                  ><span>公開しない</span></label>
                </li>
              </ul>
            </div>
            <div class="group">
              <dl class="prefectures">
                <dt>お住まいの都道府県</dt>
                <dd>
                  <div class="selectPrefectures">
                    <select v-model="user.prefecture">
                      <option
                        v-for="(pref, index) in prefectures"
                        :key="index"
                        :value="pref"
                      >
                        {{ pref }}
                      </option>
                    </select>
                  </div>
                </dd>
              </dl>
            </div>
            <div class="group">
              <dl class="mail">
                <div>
                  <dt>メールアドレス</dt>
                  <dd class="inputWrap" :class="{ error: !validUserEmail }">
                    <input
                      v-model="user.email"
                      type="text"
                      placeholder="time@mail.com"
                    >
                  </dd>
                </div>
                <div>
                  <dt>メールアドレス確認用</dt>
                  <dd class="inputWrap" :class="{ error: !validEmailConfirm }">
                    <input
                      v-model="user.email_confirm"
                      type="text"
                      placeholder="time@mail.com"
                    >
                  </dd>
                </div>
              </dl>
            </div>
            <div class="group">
              <p class="text">
                ＊新しいメールアドレスに「メールドレス変更承認」のメールが配信されまます。「承認」ボタンをクリックしていただけると、メールアドレスの変更が完了します。迷惑メールフォルダなどに振り分けられてないか、ご注意ください。
              </p>
              <dl class="password">
                <div>
                  <dt>古いパスワード</dt>
                  <dd class="inputWrap">
                    <input
                      v-model="user.password"
                      type="password"
                      placeholder="＊＊＊＊＊＊＊＊"
                    >
                  </dd>
                </div>
                <div class="password1">
                  <dt>
                    新しいパスワード<span
                      class="rule"
                    >※8文字以上16文字以内</span>
                  </dt>
                  <dd class="inputWrap" :class="{ error: !validNewPassword }">
                    <input
                      v-model="user.new_password1"
                      type="password"
                      placeholder="＊＊＊＊＊＊＊＊"
                    >
                  </dd>
                </div>
                <div class="password2">
                  <dt>新しいパスワード確認用</dt>
                  <dd class="inputWrap" :class="{ error: !validNewPassword }">
                    <input
                      v-model="user.new_password2"
                      type="password"
                      placeholder="＊＊＊＊＊＊＊＊"
                    >
                  </dd>
                </div>
              </dl>
            </div>
            <div class="group">
              <dl class="mailmagazine">
                <dt>メールマガジン配信</dt>
              </dl>
              <ul class="publish" :class="{ error: !validMailmagazine }">
                <li>
                  <label><input
                    v-model="user.mailmagazine"
                    type="radio"
                    value="true"
                  ><span>受信する</span></label>
                </li>
                <li>
                  <label><input
                    v-model="user.mailmagazine"
                    type="radio"
                    value="false"
                  ><span>受信しない</span></label>
                </li>
              </ul>
            </div>
          </div>
          <!-- /.editProfileArea -->
        </div>

        <div class="editImageCoverArea">
          <h2>プロフィール、カバー写真を登録する</h2>
          <div class="editImageArea">
            <div v-if="user.profile_image" class="changeWrap">
              <p class="image">
                <img
                  :src="user.profile_image + '?d=200x200'"
                  alt=""
                  :class="profileImageClassString"
                  @load="onProfileImageLoaded"
                >
              </p>
              <p class="changeImage">
                <a
                  class="openModal"
                  @click="openProfileUpload"
                >プロフィール写真を変更する</a>
              </p>
            </div>
            <div v-else class="addWrap">
              <p class="addImage">
                <a @click="openProfileUpload">プロフィール写真を登録する</a>
              </p>
            </div>
          </div>
          <div class="editCoverArea">
            <div v-if="user.cover_image" class="changeWrap">
              <p class="image">
                <img
                  :src="user.cover_image"
                  alt=""
                  :class="coverImageClassString"
                  @load="onCoverImageLoaded"
                >
              </p>
              <p class="changeCover">
                <a @click="openCoverUpload">カバー写真を変更する</a>
              </p>
            </div>
            <div v-else class="addWrap">
              <p class="addCover">
                <a @click="openCoverUpload">カバー写真を登録する</a>
              </p>
            </div>
          </div>
          <!-- /.editImageCoverArea -->
        </div>

        <div class="buttonArea">
          <div class="inner">
            <p class="cancel">
              <button type="submit" @click="cancel($event)">
                キャンセル
              </button>
            </p>
            <p class="submit">
              <button
                type="submit"
                :disabled="formInvalid"
                @click="updateUser($event)"
              >
                登録
              </button>
            </p>
          </div>
          <div class="inner">
            <p class="withdraw">
              <button type="submit" class="button" @click="deleteUser($event)">
                解約する
              </button>
            </p>
          </div>
          <!-- /.buttonArea -->
        </div>
      </form>
      <!-- /.mainContents -->
    </div>

    <!-- モーダル -->
    <modal name="form-submitted">
      <div
        id="modalCompleteSave"
        class="modal"
        style="display: block !important;"
      >
        <div class="modalOverlay" />
        <div class="modalInner">
          <dl>
            <dt>保存完了</dt>
            <dd>設定を保存しました。</dd>
          </dl>
          <div class="buttonArea">
            <p class="back">
              <a class="closeModal" @click="closeModal">設定の編集へ戻る</a>
            </p>
          </div>
          <p class="close">
            <a class="closeModal" @click="closeModal" />
          </p>
        </div>
      </div>
    </modal>

    <modal
      name="profileupload"
      :width="'100%'"
      :height="'auto'"
      :scrollable="true"
      @opened="modalPositionControl('modalProfileImage', 1400)"
    >
      <div class="modalOverlay" />
      <div id="modalProfileImage">
        <div class="modalInner" :class="modalProfileImageClass">
          <div class="modalPosition">
            <dl>
              <dt class="title">
                プロフィール写真を登録する
              </dt>
              <dd class="read">
                ご自身の写真を使うか、デフォルトのアイコンを選択できます。
              </dd>
              <div id="profilePhoto">
                <dt>ご自身の写真を追加する</dt>
                <dd>
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    class="upload"
                    @change="onProfileImageChange"
                  >
                </dd>
              </div>
              <dd
                id="photoEditArea"
                style="padding: 0; text-align: center; background: #eee;"
              >
                <vue-cropper
                  v-show="user.new_profile_image"
                  id="profileImage"
                  ref="profileCropper"
                  :drag-mode="'move'"
                  :view-mode="1"
                  :zoomable="false"
                  :zoom-on-touch="false"
                  :zoom-on-wheel="false"
                  :auto-crop-area="1"
                  :crop-box-movable="false"
                  :crop-box-resizable="false"
                  :toggle-drag-mode-on-dblclick="false"
                  :aspect-ratio="1 / 1"
                  :background="false"
                  :check-cross-origin="false"
                  :ready="onProfileCropperReady"
                  :src="user.new_profile_image"
                  alt="写真編集エリア"
                />
              </dd>
              <div id="photoColor">
                <dt>デフォルトアイコンから選ぶ</dt>
                <dd>
                  <div id="samplePhotoColor" :class="profileColorClass">
                    <img src="/img/ic_sample_profile.png" alt="">
                  </div>
                  <div id="selectPhotoColor">
                    <p>カラーを選択：</p>
                    <ul>
                      <li
                        class="color01"
                        :class="new_profile_color === 'color01' ? 'active' : ''"
                      >
                        <a @click="changeProfileColorTo('color01')">カラー1</a>
                      </li>
                      <li
                        class="color02"
                        :class="new_profile_color === 'color02' ? 'active' : ''"
                      >
                        <a @click="changeProfileColorTo('color02')">カラー2</a>
                      </li>
                      <li
                        class="color03"
                        :class="new_profile_color === 'color03' ? 'active' : ''"
                      >
                        <a @click="changeProfileColorTo('color03')">カラー3</a>
                      </li>
                      <li
                        class="color04"
                        :class="new_profile_color === 'color04' ? 'active' : ''"
                      >
                        <a @click="changeProfileColorTo('color04')">カラー4</a>
                      </li>
                      <li
                        class="color05"
                        :class="new_profile_color === 'color05' ? 'active' : ''"
                      >
                        <a @click="changeProfileColorTo('color05')">カラー5</a>
                      </li>
                    </ul>
                  </div>
                </dd>
              </div>
            </dl>
            <div class="buttonArea">
              <div class="inner">
                <p class="cancel">
                  <a @click="closeProfileUpload">キャンセル</a>
                </p>
                <p class="back">
                  <a @click="closeProfileUpload">キャンセル</a>
                </p>
                <p class="submit">
                  <button
                    type="submit"
                    :disabled="profileImageInvalid"
                    @click="updateProfileImage($event)"
                  >
                    保存する
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="coverupload"
      :width="'100%'"
      :height="'auto'"
      :scrollable="true"
      @opened="modalPositionControl('modalArtwork', 1400)"
    >
      <div class="modalOverlay" />
      <div id="modalArtwork">
        <div class="modalInner" :class="modalCoverImageClass">
          <div class="modalPosition">
            <dl>
              <dt class="title">
                カバー写真を登録する
              </dt>
              <dd class="read">
                ご自身の写真を使う、アートワークから選ぶ、またはデフォルトのカバーを選択できます。
              </dd>
              <div id="coverPhoto">
                <dt>ご自身の写真を追加する</dt>
                <dd>
                  <input
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    class="upload"
                    @change="onCoverImageChange"
                  >
                </dd>
              </div>
              <dd
                id="coverEditArea"
                style="padding: 0; text-align: center; background: #eee;"
              >
                <vue-cropper
                  v-show="user.new_cover_image"
                  id="coverImage"
                  ref="cropper"
                  :drag-mode="'move'"
                  :view-mode="1"
                  :zoomable="false"
                  :zoom-on-touch="false"
                  :zoom-on-wheel="false"
                  :auto-crop-area="1"
                  :crop-box-movable="false"
                  :crop-box-resizable="false"
                  :toggle-drag-mode-on-dblclick="false"
                  :aspect-ratio="1200 / 260"
                  :background="false"
                  :check-cross-origin="false"
                  :ready="onCropperReady"
                  :src="user.new_cover_image"
                  alt="カバー写真"
                />
              </dd>
              <div id="coverColor">
                <dt>デフォルトカバーから選ぶ</dt>
                <dd>
                  <div id="sampleCoverColor" :class="coverColorClass" />
                  <div id="selectCoverColor">
                    <p>カラーを選択：</p>
                    <ul>
                      <li
                        class="color01"
                        :class="new_cover_color === 'color01' ? 'active' : ''"
                      >
                        <a @click="changeCoverColorTo('color01')">カラー1</a>
                      </li>
                      <li
                        class="color02"
                        :class="new_cover_color === 'color02' ? 'active' : ''"
                      >
                        <a @click="changeCoverColorTo('color02')">カラー2</a>
                      </li>
                      <li
                        class="color03"
                        :class="new_cover_color === 'color03' ? 'active' : ''"
                      >
                        <a @click="changeCoverColorTo('color03')">カラー3</a>
                      </li>
                      <li
                        class="color04"
                        :class="new_cover_color === 'color04' ? 'active' : ''"
                      >
                        <a @click="changeCoverColorTo('color04')">カラー4</a>
                      </li>
                      <li
                        class="color05"
                        :class="new_cover_color === 'color05' ? 'active' : ''"
                      >
                        <a @click="changeCoverColorTo('color05')">カラー5</a>
                      </li>
                    </ul>
                  </div>
                </dd>
              </div>
              <div id="coverArtwork">
                <dt>アートワーク</dt>
                <div id="selectArtwork">
                  <dd
                    v-for="cover_image in cover_images"
                    :key="cover_image.id"
                    :class="
                      user.new_art_image === cover_image.image ? 'active' : ''
                    "
                  >
                    <a
                      @click="setCoverImage(cover_image)"
                    ><img
                      :src="cover_image.image + '?d=200x200'"
                      alt=""
                    ></a>
                  </dd>
                </div>
              </div>
            </dl>
            <div class="buttonArea">
              <div class="inner">
                <p class="cancel">
                  <a class="closeModal" @click="closeCoverUpload">キャンセル</a>
                </p>
                <p class="back">
                  <a @click="closeCoverUpload">キャンセル</a>
                </p>
                <p class="submit">
                  <button
                    type="submit"
                    :disabled="coverImageInvalid"
                    @click="updateCoverImage($event)"
                  >
                    保存する
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal name="error">
      <div
        id="modalCompleteSave"
        class="modal"
        style="display: block !important;"
      >
        <div class="modalOverlay" />
        <div class="modalInner">
          <dl>
            <dt>エラー</dt>
            <dd>{{ modalError }}</dd>
          </dl>
          <div class="buttonArea">
            <div class="inner">
              <p class="back">
                <a class="closeModal" @click="closeModal">設定の編集へ戻る</a>
              </p>
            </div>
          </div>
          <p class="close">
            <a class="closeModal" @click="closeModal" />
          </p>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import UserTitle from '@/components/UserTitle.vue'
import UserNav from '@/components/UserNav.vue'
import UserNavSp from '@/components/UserNavSp.vue'
import NProgress from 'nprogress'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import {
  fieldNotNull,
  validEmail,
  fieldsEqual,
  isZenKatakana,
  validDate
} from '@/helpers/validator.js'
import store from '@/store/index'
import GraphQLService from '@/services/GraphQLService.js'

export default {
  components: {
    VueCropper,
    UserTitle,
    UserNav,
    UserNavSp
  },
  props: {
    u: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      user: null,
      isUploading: false,
      cover_images: [],
      showCoverUpload: false,
      profileCropperReady: false,
      cropperReady: false,
      modalError: null,
      new_profile_color: null,
      new_cover_color: null,
      profileImageClassString: 'obj-fit-img-contain',
      coverImageClassString: 'obj-fit-img'
    }
  },
  computed: {
    profileColorClass: function () {
      return this.new_profile_color
    },
    coverColorClass: function () {
      return this.new_cover_color
    },
    profileImageInvalid: function () {
      if (this.isUploading) {
        return true
      }
      if (
        this.user.new_profile_image !== null &&
        this.profileCropperReady === false
      ) {
        return true
      }
      if (
        this.profileCropperReady === false &&
        this.new_profile_color === null
      ) {
        return true
      }

      return false
    },
    coverImageInvalid: function () {
      if (this.isUploading) {
        return true
      }
      if (this.user.new_cover_image !== null && this.cropperReady === false) {
        return true
      }
      if (
        this.cropperReady === false &&
        this.new_cover_color === null &&
        this.user.new_art_image === null
      ) {
        return true
      }

      return false
    },
    modalProfileImageClass: function () {
      if (this.user.new_profile_image !== null) {
        return 'modePhoto'
      }
      return ''
    },
    modalCoverImageClass: function () {
      if (this.user.new_cover_image !== null) {
        return 'modePhoto'
      }
      if (this.user.new_art_image !== null) {
        return 'modeArtwork'
      }
      return ''
    },
    validLastname: function () {
      return fieldNotNull(this.user.lastname)
    },
    validFirstname: function () {
      return fieldNotNull(this.user.firstname)
    },
    validLastnameKana: function () {
      return (
        fieldNotNull(this.user.lastname_kana) &&
        isZenKatakana(this.user.lastname_kana)
      )
    },
    validFirstnameKana: function () {
      return (
        fieldNotNull(this.user.firstname_kana) &&
        isZenKatakana(this.user.firstname_kana)
      )
    },
    validGender: function () {
      return fieldNotNull(this.user.gender)
    },
    validBirthdayYear: function () {
      return fieldNotNull(this.user.birthday_year)
    },
    validBirthdayMonth: function () {
      return fieldNotNull(this.user.birthday_month)
    },
    validBirthdayDay: function () {
      return fieldNotNull(this.user.birthday_day)
    },
    validBirthday: function () {
      return validDate(
        this.user.birthday_year,
        this.user.birthday_month,
        this.user.birthday_day
      )
    },
    validBirthdayDisplay: function () {
      return fieldNotNull(this.user.birthday_display)
    },
    validPrefecture: function () {
      return fieldNotNull(this.user.prefecture)
    },
    validUserEmail: function () {
      return fieldNotNull(this.user.email) && validEmail(this.user.email)
    },
    validEmailConfirm: function () {
      return fieldsEqual(this.user.email, this.user.email_confirm)
    },
    validNewPassword: function () {
      return fieldsEqual(this.user.new_password1, this.user.new_password2)
    },
    validMailmagazine: function () {
      return fieldNotNull(this.user.mailmagazine)
    },
    formInvalid: function () {
      if (this.isUploading) {
        return true
      }

      if (!this.validLastname) {
        return true
      }

      if (!this.validFirstname) {
        return true
      }

      if (!this.validLastnameKana) {
        return true
      }

      if (!this.validFirstnameKana) {
        return true
      }

      if (!this.validGender) {
        return true
      }

      if (!this.validBirthday) {
        return true
      }

      if (!this.validBirthdayDisplay) {
        return true
      }

      if (!this.validPrefecture) {
        return true
      }

      if (!this.validUserEmail) {
        return true
      }

      if (!this.validEmailConfirm) {
        return true
      }

      if (!this.validMailmagazine) {
        return true
      }

      return false
    },
    years: function () {
      var years = {}
      for (let i = 1920; i <= 2020; i++) {
        years[i] = i
      }
      return years
    },
    months: function () {
      var months = {}
      for (let i = 1; i <= 12; i++) {
        months[i] = i
      }
      return months
    },
    days: function () {
      var days = {}
      for (let i = 1; i <= 31; i++) {
        days[i] = i
      }
      return days
    },
    prefectures: function () {
      return {
        1: '北海道',
        2: '青森県',
        3: '岩手県',
        4: '宮城県',
        5: '秋田県',
        6: '山形県',
        7: '福島県',
        8: '茨城県',
        9: '栃木県',
        10: '群馬県',
        11: '埼玉県',
        12: '千葉県',
        13: '東京都',
        14: '神奈川県',
        15: '新潟県',
        16: '富山県',
        17: '石川県',
        18: '福井県',
        19: '山梨県',
        20: '長野県',
        21: '岐阜県',
        22: '静岡県',
        23: '愛知県',
        24: '三重県',
        25: '滋賀県',
        26: '京都府',
        27: '大阪府',
        28: '兵庫県',
        29: '奈良県',
        30: '和歌山県',
        31: '鳥取県',
        32: '島根県',
        33: '岡山県',
        34: '広島県',
        35: '山口県',
        36: '徳島県',
        37: '香川県',
        38: '愛媛県',
        39: '高知県',
        40: '福岡県',
        41: '佐賀県',
        42: '長崎県',
        43: '熊本県',
        44: '大分県',
        45: '宮崎県',
        46: '鹿児島県',
        47: '沖縄県'
      }
    }
  },
  created: function () {
    this.user = this.u
    this.populateUser()
    this.getArtworks()
  },
  mounted () {},
  methods: {
    populateUser () {
      this.user.new_profile_image = null
      this.user.new_cover_image = null
      this.user.new_art_image = null

      this.user.new_password1 = null
      this.user.new_password2 = null

      var t = this.user.birthday.split(/[- :]/)
      var d = new Date(t[0], t[1] - 1, t[2], t[3], t[4], t[5])
      const birthday = new Date(d)

      const extraProperties = {
        email_confirm: this.user.email,
        birthday_year: birthday.getFullYear(),
        birthday_month: birthday.getMonth() + 1,
        birthday_day: birthday.getDate()
      }

      this.user = Object.assign({}, this.user, extraProperties)
    },
    disableScroll () {
      document
        .getElementsByTagName('html')[0]
        .setAttribute('class', 'noScroll')
      document.body.classList.add('noScroll')
    },
    enableScroll () {
      document.body.classList.remove('noScroll')
      document.getElementsByTagName('html')[0].setAttribute('class', '')
    },
    closeModal () {
      this.$modal.hide('form-submitted')
      this.$modal.hide('error')
      this.enableScroll()
    },
    setCoverImage (coverImage) {
      this.user.new_art_image = coverImage.image
    },
    openCoverUpload () {
      this.disableScroll()
      this.$modal.show('coverupload')
    },
    closeCoverUpload () {
      this.$modal.hide('coverupload')
      this.user.new_cover_image = null
      this.user.new_art_image = null
      this.new_cover_color = null
      this.cropperReady = false
      this.enableScroll()
    },
    openProfileUpload () {
      this.disableScroll()
      this.$modal.show('profileupload')
    },
    closeProfileUpload () {
      this.user.new_profile_image = null
      this.profileCropperReady = false
      this.$modal.hide('profileupload')
      this.enableScroll()
    },
    onProfileImageLoaded () {
      if (this.user.profile_image === '/img/loading.gif') {
        this.profileImageClassString = 'obj-loading'
      } else {
        this.profileImageClassString = 'obj-fit-img-contain'
      }
    },
    updateProfileImage () {
      if (this.user.new_profile_image !== null) {
        this.isUploading = true
        this.user.profile_image = '/img/loading.gif'
        this.closeProfileUpload()
        this.$refs.profileCropper.getCroppedCanvas().toBlob(blob => {
          store
            .dispatch('user/uploadImage', blob)
            .then(res => {
              this.isUploading = false
              this.user.profile_image = res.data.file.url
            })
            .catch(() => {
              this.isUploading = false
            })
        }, 'image/jpeg')
      } else if (this.new_profile_color !== null) {
        this.user.profile_image = null
        this.user.profile_color = this.new_profile_color
        this.new_profile_color = null
        this.closeProfileUpload()
      } else {
        this.closeProfileUpload()
      }
    },
    onCoverImageLoaded () {
      if (this.user.cover_image === '/img/loading.gif') {
        this.coverImageClassString = 'obj-loading'
      } else {
        this.coverImageClassString = 'obj-fit'
      }
    },
    updateCoverImage () {
      if (this.user.new_cover_image !== null) {
        this.isUploading = true
        this.user.cover_image = '/img/loading.gif'
        this.closeCoverUpload()
        this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
          store
            .dispatch('user/uploadImage', blob)
            .then(res => {
              this.isUploading = false
              this.user.cover_image = res.data.file.url
            })
            .catch(() => {
              this.isUploading = false
            })
        }, 'image/jpeg')
      } else if (this.new_cover_color !== null) {
        this.user.cover_image = null
        this.user.cover_color = this.new_cover_color
        this.new_cover_color = null
        this.closeCoverUpload()
      } else if (this.user.new_art_image !== null) {
        this.user.cover_image = this.user.new_art_image
        this.closeCoverUpload()
      }
    },
    removeCoverImage () {
      this.user.cover_image = null
    },
    onProfileCropperReady (event) {
      setTimeout(() => {
        /** https://github.com/fengyuanchen/cropperjs/issues/258 */
        const canvasImage = window.document.querySelector('img.cropper-hide')
        const src = canvasImage.getAttribute('src')
        canvasImage.setAttribute('crossorigin', 'anonymous')
        canvasImage.setAttribute('src', src)
      }, 100)
      this.profileCropperReady = true
    },
    onCropperReady (event) {
      setTimeout(() => {
        /** https://github.com/fengyuanchen/cropperjs/issues/258 */
        const canvasImage = window.document.querySelector('img.cropper-hide')
        const src = canvasImage.getAttribute('src')
        canvasImage.setAttribute('crossorigin', 'anonymous')
        canvasImage.setAttribute('src', src)
      }, 100)
      this.cropperReady = true
    },
    cropImage () {},
    cancel (event) {
      event.preventDefault()
      store.dispatch('user/fetchUser', {}).then(user => {
        if (user) {
          this.user = user
          this.populateUser()
        }
      })
    },
    updateUser (event) {
      event.preventDefault()
      this.isUploading = true
      NProgress.start()

      store
        .dispatch('user/updateUser', this.user)
        .then(res => {
          store.commit('SET_LOGGED_IN_USER', res.data)
          this.$modal.show('form-submitted')

          this.isUploading = false
          NProgress.done()
        })
        .catch(err => {
          this.isUploading = false
          NProgress.done()

          this.modalError = err.response.data.error
          this.$modal.show('error')
        })
    },
    onProfileImageChange (e) {
      this.isUploading = true

      const files = e.target.files || e.dataTransfer.files

      const reader = new FileReader()
      reader.onload = e => {
        this.user.new_profile_image = e.target.result
        this.$refs.profileCropper.replace(e.target.result)
        this.isUploading = false
      }
      reader.readAsDataURL(files[0])
    },
    removeProfileImage () {
      this.user.profile_image = null
    },
    onCoverImageChange (e) {
      this.isUploading = true

      const files = e.target.files || e.dataTransfer.files

      const reader = new FileReader()
      reader.onload = e => {
        this.user.new_cover_image = e.target.result
        this.$refs.cropper.replace(e.target.result)
        this.isUploading = false
      }
      reader.readAsDataURL(files[0])
    },
    deleteUser (event) {
      event.preventDefault()
      this.$router.push({ name: 'user-delete' }).catch(() => {})
    },
    getArtworks () {
      GraphQLService.getCoverImages().then(response => {
        this.cover_images = response.data.data.coverImages
      })
    },
    changeProfileColorTo (color) {
      this.new_profile_color = color
    },
    changeCoverColorTo (color) {
      this.new_cover_color = color
    },
    modalPositionControl (id, height) {
      var el = document.querySelector('.v--modal')
      el.style.height = height + 'px'
    }
  }
}
</script>

<style>
input.invalid {
  color: #630015;
  border-color: #c20c33;
  background-color: #ffd9e1;
}

.v--modal {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
}

#modalProfileImage {
  box-shadow: none;
}

#modalProfileImage .cropper-crop-box,
#modalProfileImage .cropper-view-box {
  border-radius: 50%;
}

#modalProfileImage .cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

#modalProfileImage .cropper-face {
  background-color: inherit !important;
}

#modalProfileImage .cropper-dashed,
#modalProfileImage .cropper-point.point-se,
#modalProfileImage .cropper-point.point-sw,
#modalProfileImage .cropper-point.point-nw,
#modalProfileImage .cropper-point.point-ne,
#modalProfileImage .cropper-line {
  display: none !important;
}

#modalProfileImage .cropper-view-box {
  outline: inherit !important;
}

.cropper-move {
  background-color: #eee;
}

.cropper-canvas {
  cursor: crosshair;
  background-color: #fff;
  opacity: 0;
  filter: alpha(opacity=0);
}

.editImageCoverArea .editImageArea .image img.obj-loading {
  width: 60px;
  height: 60px;
}

.editImageCoverArea .editCoverArea .image img.obj-loading {
  width: 60px;
  height: 60px;
}

#photoEditArea {
  height: 400px;
}

#profileImage {
  height: 400px;
}

#coverEditArea {
  height: 400px;
}

#coverImage {
  height: 400px;
}
</style>
